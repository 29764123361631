import img2 from './Images/img2.png'
import R_Logo from './Images/R_Logo.png'
import { Link, useHistory } from 'react-router-dom';
import './App.css'
import Pfooter from './Pfooter';
import { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import GlobalVariable from './GlobalVariable';



export default function SalesMan() {

    const [data, setData] = useState({ username: "", password: "", });

    const changeHandlers = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    const history = useHistory();

    async function checkUser(e) {

        e.preventDefault();

        // let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiI1NDY1OTc3IiwibmJmIjoxNjY5MTc5MjA3LCJleHAiOjE2NjkyNjU2MDcsImlhdCI6MTY2OTE3OTIwN30.hU69sjE-0jrPtYP77NSAmv4W4iPGQsl2osR9NOWuEdI"

        let item = {
            userId: data.username,
            password: data.password,
            wclientid: GlobalVariable.wcClient.toString(),

        };
        // let token="";

        fetch('https://popsv3.relysoft.in/api/sman/Authenticate/AuthTokenSMan/', {

            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                //"Authorization": "bearer " + token,
            },
            body: JSON.stringify(item)
        })
            .then(res => res.json())
            .then(result => {
                if (result.isSuccess) {
                    history.push("/components/salesman/SalesmanDashboard")
                    localStorage.setItem('user-info', JSON.stringify(result))
                    window.localStorage.setItem('loggedInSalesman', true);
                }
                else {
                    throw result;
                }
            }).catch(err => {
                console.error(err);
                toast.error("wrong username or password");
            });






    }



    return (
        <>
            <div className="customer_header">
                <Link to='/'><img src={img2} height={30} width={30} id="S" alt="" style={{ marginTop: "-10px" }} /></Link>
                <h5 style={{ color: "#00C0F0", fontWeight: "700", marginTop: "15px" }}>SUSHIL PHARMA LLP</h5>
                <img src={R_Logo} height={50} width={50} className="R" alt="" style={{ marginTop: "0px" }} />
            </div>



            <form onSubmit={checkUser}>
                <div className='loginForm'>
                    <div >
                        <h5
                            style={{
                                backgroundColor: "rgb(242, 241, 241)",
                                fontSize: "25px",
                                fontWeight: "300",
                                padding: "10px"
                            }}
                        >Salesman Login</h5>
                        <label
                            style={{
                                fontSize: "20px",
                                marginLeft: "10px",
                                fontWeight: "650"
                            }}
                        >Salesman Id</label><br />
                        <input

                            name="username"
                            type="text"
                            placeholder='Salesman ID'
                            onChange={changeHandlers}
                            value={data.username}
                        />
                    </div>
                    <div>
                        <label
                            style={{
                                fontSize: "20px",
                                marginLeft: "10px",
                                fontWeight: "650"
                            }}
                        >Password</label><br />
                        <input
                            // id="email"
                            name="password"
                            type="password"
                            placeholder='Password '
                            onChange={changeHandlers}
                            value={data.password}
                        />
                    </div>
                    <button
                        type="submit"
                        className='btns'
                        style={{
                            backgroundColor: "red",
                            color: "white"
                        }}
                    >Sign In</button>
                </div>
            </form>


            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover

            />
            <Pfooter />
        </>
    )
}